let stickymenu;
let stickyRefOffset;
let initialWidth;
let menuHeight;
let bottomOffset;

function applySticky() {
    if (window.pageYOffset >= stickyRefOffset) {
        stickymenu.classList.add("sticky")
        stickymenu.setAttribute("style", "width:" + initialWidth + "px");
    } else {
        stickymenu.classList.remove("sticky");
    }
}

function initStickyMenu() {
    window.onscroll = function () {
        applySticky();
    };
    stickymenu = document.getElementById("stickymenu");
    stickyRefOffset = document.getElementById("stickyref").getBoundingClientRect().top;
    console.log("Stickyref offset: " + stickyRefOffset);
    if (stickyRefOffset < 272) {
        console.log("Overriding with 272");
        stickyRefOffset = 272;
    }
    menuHeight = stickymenu.getBoundingClientRect().height;
    if (menuHeight > 600) {
        menuHeight = 600;
    }
    console.log("Menu height: " + menuHeight);
    initialWidth = stickymenu.offsetWidth;
    applySticky();

}

export default initStickyMenu;