import * as React from "react";

class CommonHeroLow extends React.Component {
    render() {
        return <div className="kl-slideshow static-content__slideshow uh_light_gray scrollme">
            <div className="bgback">
            </div>

            <div className="kl-slideshow-inner static-content__wrapper static-content--height common-hero-low d-flex">
                <div className="static-content__source animateme" data-when="span" data-from="0" data-to="0.75"
                     data-easing="linear" data-translatey="300" data-opacity="0.1">
                    <div className="kl-bg-source">
                        <div className="kl-bg-source__bgimage"
                             style={{
                                 backgroundImage: "url(/staticimg/heros/mainhero.jpeg)",
                                 backgroundRepeat: "no-repeat",
                                 backgroundAttachment: "scroll",
                                 backgroundPositionX: "center",
                                 backgroundPositionY: "center",
                                 backgroundSize: "cover"
                             }}>
                        </div>

                        <div className="kl-bg-source__overlay"
                             style={{
                                 background: "rgba(30,115,190,0.3)",
                                 background: "-moz-linear-gradient(left, rgba(30,115,190,0.3) 0%, rgba(53,53,53,0.4) 100%)",
                                 background: "-webkit-gradient(linear, left top, right top, color-stop(0%,rgba(30,115,190,0.3)), color-stop(100%,rgba(53,53,53,0.4)))",
                                 background: "-webkit-linear-gradient(left, rgba(30,115,190,0.3) 0%,rgba(53,53,53,0.4) 100%)",
                                 background: "-o-linear-gradient(left, rgba(30,115,190,0.3) 0%,rgba(53,53,53,0.4) 100%)",
                                 background: "-ms-linear-gradient(left, rgba(30,115,190,0.3) 0%,rgba(53,53,53,0.4) 100%)",
                                 background: "linear-gradient(to right, rgba(30,115,190,0.3) 0%,rgba(53,53,53,0.4) 100%)"
                             }}>
                        </div>
                    </div>

                    <div className="th-sparkles"></div>
                </div>
                <div className="kl-bg-source__overlay"
                     style={{
                         background: "rgba(30,115,190,0.3)",
                         background: "-moz-linear-gradient(left, rgba(30,115,190,0.3) 0%, rgba(53,53,53,0.4) 100%)",
                         background: "-webkit-gradient(linear, left top, right top, color-stop(0%,rgba(30,115,190,0.3)), color-stop(100%,rgba(53,53,53,0.4)))",
                         background: "-webkit-linear-gradient(left, rgba(30,115,190,0.3) 0%,rgba(53,53,53,0.4) 100%)",
                         background: "-o-linear-gradient(left, rgba(30,115,190,0.3) 0%,rgba(53,53,53,0.4) 100%)",
                         background: "-ms-linear-gradient(left, rgba(30,115,190,0.3) 0%,rgba(53,53,53,0.4) 100%)",
                         background: "linear-gradient(to right, rgba(30,115,190,0.3) 0%,rgba(53,53,53,0.4) 100%)"
                     }}>
                </div>


            </div>
        </div>;
    }
}

export default CommonHeroLow;