import * as React from "react"
import '../../css/bootstrap.css';
import {ThemeProvider} from "react-bootstrap";

import "../../fonts/font-awesome/css/font-awesome.min.css";
import "../../css/template.css";
import "../../css/responsive.css";
import "../../css/base-sizing.css";
import "../../css/custom.css";
import "../../css/knowledge.css";

import HeaderAndMenu from "../../page_components/common/HeaderAndMenu";
import Footer from "../../page_components/common/Footer";
import BsHelmet from "../../page_components/common/BsHelmet";
import CommonHeroLow from "../../page_components/common/CommonHeroLow";
import BreadCrumbs from "./breadcrumbs";
import {StaticImage} from "gatsby-plugin-image";
import {useEffect} from "react";
import initStickyMenu from "../../page_components/common/initStickyMenu";


const AccountManagement = () => {
    useEffect(() => {
        initStickyMenu();
    }, []);

    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <BsHelmet title={"BitSpreader Documentation - Account Management"}/>
            <div id="page_wrapper">
                <HeaderAndMenu/>
                <CommonHeroLow title={"BitSpreader Documentation - Account Management"}/>
                <BreadCrumbs link={"accountmanagement"} title="Account Management"/>
                <section className="hg_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div id="stickymenu" className="sidebar knowledgebase">
                                    <div className="widget widget_categories">
                                        <h3 className="widgettitle title">SECTIONS</h3>
                                        <a href="#registration">Registration</a>
                                        <ul className="menu">
                                            <li><a href="#step1">Step 1: Personal information & password</a></li>
                                            <li><a href="#step2">Step 2: Email verification</a></li>
                                            <li><a href="#step3">Step 3: Accept Terms and Conditions</a></li>
                                        </ul>
                                        <a href="#accountmanagement">Account Management</a>
                                        <ul className="menu">
                                            <li><a href="#personaldetails">Modifying personal details</a></li>
                                            <li><a href="#changepassword">Change Password</a></li>
                                            <li><a href="#2fa">Two-factor authentication</a></li>
                                            <li><a href="#closing">Closing and Deleting BitSpreader account</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-8"}>
                                <h2 id="stickyref" className="kb-article--title">
                                    Registration & Account Management
                                </h2>

                                <a name={"registration"}><h3>Registration</h3></a>

                                <a name={"step1"}><h4>Step 1: Personal information & password</h4></a>

                                <div className={"row"}>
                                    <div className={'col-md-6'}>
                                        <br/>
                                        Please provide the following:
                                        <ul className={"list-style1"}>
                                            <li>Email</li>
                                            <li>First and last name</li>
                                            <li>Password + confirmation</li>
                                            <li>Check reCAPTCHA checkbox</li>
                                        </ul>

                                        <br/><strong>Password policy:</strong>
                                        <ul className={"list-style1"}>
                                            <li>Minimum 8 characters</li>
                                            <li>One capital letter</li>
                                            <li>One digit</li>
                                            <li>One small letter</li>
                                        </ul>
                                    </div>
                                    <div className={'col-md-6'}>
                                        <StaticImage src={"../../gatsbyimg/docs/register.png"} alt={"Registration#1"}/>
                                    </div>
                                </div>

                                <br/>
                                <a name={"step2"}><h4> Step 2: Email verification</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>

                                        In this step you will receive an email containing a confirmation link from BitSpreader. The link will be valid for 5 minutes.
                                        <br/>
                                        <i>
                                            <br/><b>Email title</b>: “Verify email”
                                            <br/><b>From:</b> noreply@bitspreader.com
                                            <br/><b>Contents:</b>
                                            <br/>
                                            <br/>Someone has created a Bitspreader account with this email address. If this was you, click the link below to verify your email address
                                            <br/>Link to e-mail address verification
                                            <br/>This link will expire within 5 minutes.
                                            <br/>If you didn't create this account, just ignore this message.
                                        </i>
                                    </div>
                                    <div className={'col-md-6'}>
                                        <StaticImage src={"../../gatsbyimg/docs/register_email.png"} alt={"Registration#1"}/>
                                    </div>
                                </div>
                                <br/><br/>

                                <a name={"step3"}><h4>Step 3: Accept Terms and Conditions</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        In order to proceed with the registration process you need to read and approve the following documents:
                                        <ul className={"list-style1 py-5"}>
                                            <li><a href="/privacy" className={""}>Privacy Policy</a></li>
                                            <li><a href="/terms">Terms and Conditions</a></li>
                                        </ul>
                                    </div>
                                    <div className={'col-md-6'}>
                                        <StaticImage src={"../../gatsbyimg/docs/register_terms.png"} alt={"Registration#1"}/>
                                    </div>
                                </div>

                                <br/><br/>
                                <br/><br/>

                                <a name={"accountmanagement"}><h3>Account Management</h3></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        In order to open account management click “Account” in the menu available in the top right corner of the screen after clicking the yellow avatar icon.

                                    </div>
                                    <div className={'col-md-6'}>
                                        <StaticImage src={"../../gatsbyimg/docs/openaccountmanagement.png"} alt={"Registration#1"}/>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                                <a name={"personaldetails"}><h4>Modifying personal details</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        In the left-hand menu select “Account” and you can modify your first and last name, after that click “Save”
                                    </div>
                                    <div className={'col-md-6'}>
                                        <StaticImage src={"../../gatsbyimg/docs/personaldetails.png"} alt={"Registration#1"}/>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                                <a name={"changepassword"}><h4>Change Password</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        In the left-hand menu select “Password”, provide old password and new password and repeat the new password, click “Save”
                                    </div>
                                    <div className={'col-md-6'}>
                                        <StaticImage src={"../../gatsbyimg/docs/changepassword.png"} alt={"Registration#1"}/>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                                <a name={"2fa"}><h4>Two-factor authentication</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        In order to open account management click “Account” in the menu available in the top right corner of the screen after clicking the yellow avatar icon or select
                                        "Account" from left sidebar menu.
                                        In the left-hand menu select “2FA Tokens”. In order to proceed you need to download one of the following applications on
                                        your mobile device:

                                        <ul className={"list-style1 py-5"}>
                                            <li>FreeOTP</li>
                                            <li>Google Authenticator</li>
                                        </ul>


                                        After installing either of these applications use “+” to add new configuration code and select the camera icon to scan the QR code. The app may require you to
                                        approve
                                        the permissions to access your camera. After scanning the QR code, the new entry called bitspreader, followed by your email address will be visible on the list
                                        of
                                        one-time codes. The one-time code is refreshed every 60 seconds. You need to provide the one-time code in the “One-time code” field before it is refreshed with
                                        the new
                                        code. “Device name” field is the optional label of your mobile device (as you may have more than one installation of Authenticator applications on different
                                        devices,
                                        this label will be used to identify which device does it refer to).

                                        <br/><br/>
                                        <b>Warning:</b> It is possible to operate with BitSpreader without any 2FA token, but this is NOT RECOMMENDED due to security reasons.
                                    </div>
                                    <div className={'col-md-6'}>
                                        <StaticImage src={"../../gatsbyimg/docs/change2fastart.png"} alt={"Registration#1"}/>
                                        <br/><br/><StaticImage src={"../../gatsbyimg/docs/change2fa.png"} alt={"Registration#1"}/>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                                <a name={"closing"}><h4>Closing and Deleting BitSpreader account</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/>
                                        In order to close your account please send a request with the title “BitSpreader account deletion request” from the email address associated with your account.
                                        We will contact you to confirm your request. The process of deleting your account may take up to 5 working days. Please refer to terms and conditions to find
                                        out more about our account deletion policy and data archiving policies.
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        </ThemeProvider>
    )
}

export default AccountManagement;
