import * as React from "react";

const BreadCrumbs = ({link, title}) => {
    return <div>
        <div className="kb-breadcrumbs">
            <div className="container">
                <div className="kb-breadcrumbs-container">
                    <ul>
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li>
                            <a href="/docs">BitSpreader Documentation</a>
                        </li>
                        <li className="active">
                            <a href={"/docs/" + link}>{title}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>;
}

export default BreadCrumbs;